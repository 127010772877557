import React, { useState } from "react";
import { strings } from "../../resources/strings";
import { Appointment } from "../../apis/API";
import { dateStamp, timeStamp } from "../../utils/UtilityFunctions";

export interface ApptTileProps {
  appt?: Appointment;
  court?: Appointment;
}

const ApptInfoTile: React.FC<ApptTileProps> = ({ appt, court }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  let firstLineAppt = "";
  let restOfContentAppt = "";
  let apptWords = [];
  if (appt && appt.appointmentInstructions) {
    apptWords = appt.appointmentInstructions.split(" ");
    if (apptWords.length <= 10) {
      firstLineAppt = appt.appointmentInstructions;
    } else {
      firstLineAppt = apptWords.slice(0, 10).join(" ");
      restOfContentAppt = apptWords.slice(10).join(" ");
    }
  }

  let firstLineCourt = "";
  let restOfContentCourt = "";
  let courtWords = [];

  if (court && court.notes) {
    courtWords = court.notes.split(" ");
    if (courtWords.length <= 10) {
      firstLineCourt = court.notes;
    } else {
      firstLineCourt = courtWords.slice(0, 10).join(" ");
      restOfContentCourt = courtWords.slice(10).join(" ");
    }
  }
  return (
    <div className="card bg-primary text-accent">
      <div className="card-body py-5 px-6">
        {/* Render appointment tile */}
        {appt && (
          <>
            <h2 className="card-title flex flex-row justify-between">
              {strings.appointments.appointmentTileTitle}
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                  />
                </svg>
              </span>
            </h2>
            <div className="flex flex-col">
              <div className="flex flex-row justify-between w-full">
                <p>{appt.resolvedAppointmentType?.label}</p>
                <p className="font-semibold text-right">
                  {appt.time && (
                    <>
                      {dateStamp(appt.time)} @{timeStamp(appt.time)}
                    </>
                  )}
                </p>
              </div>
              <p className="mt-3 font-bold">{appt.location?.name}</p>
              <div className="font-light">
                <p>
                  {appt.location?.number} {appt.location?.street}
                </p>
                <p>
                  {appt.location?.city}, {appt.location?.state}{" "}
                  {appt.location?.postalCode}
                </p>
              </div>
              &nbsp;
              <div className="font-light">
                <p>
                  <strong>Instructions:</strong> {firstLineAppt}
                  {!isCollapsed && restOfContentAppt && (
                    <span id="notes">&nbsp;{restOfContentAppt}</span>
                  )}
                  &nbsp;
                  {apptWords.length > 10 && (
                    <button onClick={toggleCollapse}>
                      {isCollapsed ? "▼" : "▲"}
                    </button>
                  )}
                </p>
              </div>
            </div>
          </>
        )}

        {/* Render the Upcoming Court Date tile */}
        {court && (
          <>
            <h2 className="card-title flex flex-row justify-between">
              {strings.appointments.courtTileTitle}
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z"
                  />
                </svg>
              </span>
            </h2>
            <div className="flex flex-col">
              <span className="font-semibold text-right">
                {court.time && (
                  <>
                    {dateStamp(court.time)} @{timeStamp(court.time)}
                  </>
                )}
              </span>
              <p className="mt-3 font-bold">{court.location?.name}</p>
              <div className="font-light">
                <p>
                  {court.location?.number} {court.location?.street}
                </p>
                <p>
                  {court.location?.city} {court.location?.state}{" "}
                  {court.location?.postalCode}
                </p>
              </div>
              &nbsp;
              <div className="font-light">
                <p>
                  <strong>Instructions:</strong> {firstLineCourt}
                  {!isCollapsed && restOfContentCourt && (
                    <span id="notes">&nbsp;{restOfContentCourt}</span>
                  )}
                  &nbsp;
                  {courtWords.length > 10 && (
                    <button onClick={toggleCollapse}>
                      {isCollapsed ? "▼" : "▲"}
                    </button>
                  )}
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ApptInfoTile;
