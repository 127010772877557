import { Navigate } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import { routes } from "../../router/routes";

const formFields = {
  signIn: {
    username: {
      dialCodeList: ["+1", "+63"],
      autocomplete: "off",
    },
  },
  forgotPassword: {
    username: {
      autocomplete: "off",
    },
  },
};

const Login = () => {
  return (
    <div className="flex h-screen items-center justify-center w-90 bg-primary">
      <Authenticator
        hideSignUp={true}
        formFields={formFields}
        loginMechanisms={["username"]}
      >
        {() => <Navigate to={routes.dashboard.path} />}
      </Authenticator>
    </div>
  );
};

export default Login;
