import { useState, Fragment, useEffect, useRef } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import parse from "html-react-parser";
import { styles } from "../../shared-styles/styles";
import { AcceptTermsAndConditionsInput, Case, StepInput } from "../../apis/API";
import LoadingIndicator from "../shared/LoadingIndicator";
import { strings } from "../../resources/strings";
import Utils, { getCurrentTimeInSeconds } from "../../utils/UtilityFunctions";
import { ACCEPT_PARTICIPANT_TERMS_AND_CONDITIONS } from "../../apis/mutations/acceptParticipantTermsAndConditions";
import { GET_TERMS_AND_CONDITIONS } from "../../apis/queries/getTermsAndConditions";

interface OnboardingModalProps {
  isOpen: boolean;
  participantCase?: Case | null;
  refreshCase: () => void; // Callback method to re-query the case after submitting Question Answers
  addOnboardingStep: (step: StepInput) => void; // Mutation to track the onboarding steps
  launchCheckInFromOnboarding: () => void; // Callback method to launch the check-in process after accepting terms
}

enum OnboardingStep {
  TERMS_OF_USE = "TermsAndConditions",
  CHECKIN_AND_QUESTIONS = "CheckInAndQuestions",
}

const OnboardingProcess: React.FC<OnboardingModalProps> = ({
  isOpen,
  participantCase,
  refreshCase,
  addOnboardingStep,
  launchCheckInFromOnboarding,
}) => {
  // Queries
  const { error: loadTermsError, data: termsData } = useQuery(
    GET_TERMS_AND_CONDITIONS,
  );

  // Mutations
  const [acceptParticipantTermsAndConditionsMutation] = useMutation(
    ACCEPT_PARTICIPANT_TERMS_AND_CONDITIONS,
  );

  // UI states
  const [currentStep, setCurrentStep] = useState<OnboardingStep>(
    OnboardingStep.TERMS_OF_USE,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [termsAndConditionsString, setTermsAndConditionsString] =
    useState<string>("");
  const [termsScrolledToBottom, setTermsScrolledToBottom] =
    useState<boolean>(false);

  // To force the terms of use to stay scrolled to the top on render, we need a ref to control it
  const termsContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (termsData?.getTermsAndConditions?.document?.localization[0]?.text) {
      setTermsAndConditionsString(
        termsData.getTermsAndConditions.document.localization[0].text,
      );
    }
  }, [termsData]);

  const handleNextStep = (step: OnboardingStep) => {
    setIsLoading(false);
    setCurrentStep(step);
    if (step === OnboardingStep.CHECKIN_AND_QUESTIONS) {
      launchCheckInFromOnboarding();
    }
  };

  /**
   * Scrolls the terms and conditions to the top
   * - By default, the terms and conditions are scrolled to the bottom when rendered
   */
  const scrollTermsToTop = () => {
    // Check if bottom was reached, if so, don't scroll to top
    if (termsScrolledToBottom) return;

    // Small delay is needed to ensure the content is rendered before scrolling
    setTimeout(() => {
      if (termsContentRef.current) {
        termsContentRef.current.scrollTop = 0;
      }
    }, 150);
  };

  /**
   * Ensures that the user has scrolled to the bottom of the terms and conditions before
   * enabling the accept button
   */
  const handleScroll = () => {
    if (termsContentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = termsContentRef.current;

      // Check if the user has scrolled to the bottom
      const isScrolledToBottom = Math.ceil(scrollTop + clientHeight) === scrollHeight;

      // Update the button state
      if (isScrolledToBottom && !termsScrolledToBottom) {
        setTermsScrolledToBottom(true);
      }
    }
  };

  const performAcceptTermsMutation = async () => {
    const input: AcceptTermsAndConditionsInput = {
      versionId: termsData?.getTermsAndConditions?.document?.version || 1,
      time: getCurrentTimeInSeconds(),
    };
    try {
      setIsLoading(true);

      const result = await acceptParticipantTermsAndConditionsMutation({
        variables: { input },
      });

      if (result.data?.acceptParticipantTermsAndConditions === "true") {
        Utils.toastSuccess(strings.toast.acceptTermsSuccess);

        // Track the step via parent mutation
        addOnboardingStep({ index: 0, name: "Terms and Conditions" });

        // Setting this step will call back to the parent component to dismiss the terms
        // and launch the check-in process
        handleNextStep(OnboardingStep.CHECKIN_AND_QUESTIONS);
      } else {
        Utils.toastError(strings.toast.acceptTermsError);
      }
    } catch (error) {
      console.error(
        "acceptParticipantTermsAndConditionsMutation() -> error: ",
        error,
      );
      setIsLoading(false);
    }
  };

  // RENDER METHODS

  /**
   * Renders the current step of the onboarding process
   */

  const renderLoadingState = () => {
    return (
      <div className="absolute inset-0 w-fit h-fit mx-auto my-auto">
        <LoadingIndicator />
        <h5 className={styles.modalHeader}>{strings.submitting}</h5>
      </div>
    );
  };

  const renderParsedTerms = () => {
    return (
      <div
        ref={termsContentRef}
        onScroll={handleScroll}
        className="w-full h-96 overflow-y-scroll scroll-smooth outline outline-gray-200 outline-[2px] p-4 rounded-lg hover:shadow-lg"
      >
        {parse(termsAndConditionsString)}
      </div>
    );
  };

  const renderTermsOfUse = () => {
    return (
      <>
        <Dialog.Title>
          <div className={styles.modalHeader}>{strings.termsModalTitle}</div>
        </Dialog.Title>

        <Dialog.Description className="text-black pb-2">
          {strings.termsModalText}
        </Dialog.Description>
        <Dialog.Panel className="mt-2">
          {renderParsedTerms()}
          <div className="w-full mt-4 flex flex-row justify-end text-center items-center">
            <button
              onClick={() => performAcceptTermsMutation()}
              disabled={!termsScrolledToBottom}
              className="btn bg-primary hover:bg-green-600 not-italic text-white p-4 px-5 rounded-lg "
            >
              {strings.acceptTermsBtn}
            </button>
          </div>
        </Dialog.Panel>

        {/* Ensures that the terms text is not auto-scrolled to the bottom on render */}
        {termsAndConditionsString && scrollTermsToTop()}
      </>
    );
  };

  const renderLoadTermsError = () => {
    console.error("Error loading terms and conditions: ", loadTermsError);
    return (
      <>
        <Dialog.Title>
          <div className={styles.modalHeader}>{strings.termsModalTitle}</div>
        </Dialog.Title>

        <Dialog.Panel className="mt-4 w-full flex flex-row justify-center font-bold text-red-800">
          {strings.toast.loadTermsAndConditionsError}
        </Dialog.Panel>
      </>
    );
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      {/* Setting `static` and a null close handler prevents the user from dismissing the dialog by
          clicking outside or pressing the `ESC` key.
        */}
      <Dialog static onClose={() => null}>
        {/*
         * The backdrop - rendered as a fixed sibling to the panel container
         * Rendered with fade-in/out transitions
         */}
        <Transition.Child
          as={Fragment}
          enter="ease-in duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-out duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/90" aria-hidden="true" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="transition-all ease-in-out duration-200"
          enterFrom="opacity-0 scale-50"
          enterTo="opacity-100 scale-100"
          leave="transition-all ease-in-out duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-50"
        >
          <div className="fixed inset-0 w-screen overflow-y-auto transition-all duration-300">
            {/* Container to center the panel */}
            <div className="flex min-h-full min-w-full items-center justify-center ">
              <Dialog.Panel className="min-w-[60%] max-w-[90%] min-h-[40vh] p-5 m-5 rounded-xl border-none shadow-md drop-shadow-md bg-white">
                {/* Terms loading or awaiting result from the accept-terms mutation */}
                {isLoading && renderLoadingState()}

                {/* If an error occurred, display an error in the modal instead of being blank */}
                {!isLoading &&
                  !Utils.isNullOrUndefined(loadTermsError) &&
                  renderLoadTermsError()}

                {/* If the terms have loaded, render the terms of use */}
                {currentStep === OnboardingStep.TERMS_OF_USE &&
                  !isLoading &&
                  Utils.isNullOrUndefined(loadTermsError) &&
                  renderTermsOfUse()}

                {/* Add additional Onboarding steps here */}
                {/* Since the check-in process is launched via a separate component in the parent (Dashboard),
                 * we don't need to render it here. Instead, we can call the parent method to launch the check-in process
                 * when we update the current step in the handle method
                 */}
              </Dialog.Panel>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default OnboardingProcess;
