import { onCLS, onINP, onLCP } from "web-vitals";

const reportWebVitals = (logger?: any) => {
  if (logger) {
    onCLS(logger);
    onINP(logger);
    onLCP(logger);
  }
};

export default reportWebVitals;
