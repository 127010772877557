/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type UploadParticipantDocumentInput = {
  s3Key: string;
  fileName: string;
  time: number;
};

export type AcceptTermsAndConditionsInput = {
  versionId: number;
  time: number;
};

export type CreateParticipantAnswersInput = {
  promptId: string;
  questionGroupId: string;
  createdAt: number;
  checkInId?: string | null;
  identifiableAnswers: Array<IdentifiableAnswerInput | null>;
};

export type IdentifiableAnswerInput = {
  questionId: string;
  answer: boolean;
  notes?: string | null;
};

export type SaveRawEventInput = {
  id: string;
  type: EventType;
  clientTime: number;
  deviceInfo?: string | null;
  data?: string | null;
};

export enum EventType {
  AgencyQuestionAnswer = "AgencyQuestionAnswer",
  AuthenticationExpired = "AuthenticationExpired",
  AuthenticationStateSignOut = "AuthenticationStateSignOut",
  CheckIn = "CheckIn",
  CheckIn_BadLocation = "CheckIn_BadLocation",
  CheckInContainer = "CheckInContainer",
  CheckInLocation = "CheckInLocation",
  CheckInPhotoTaken = "CheckInPhotoTaken",
  CheckInSubmitted = "CheckInSubmitted",
  courtDate = "courtDate",
  courtDateAttendance = "courtDateAttendance",
  courtDateAttendanceResponse = "courtDateAttendanceResponse",
  DirectMessage = "DirectMessage",
  exclusionZoneViolation = "exclusionZoneViolation",
  FailedCheckIn = "FailedCheckIn",
  GeoFenceContainer = "GeoFenceContainer",
  GeoFenceEvent = "GeoFenceEvent",
  HomeRelocationRejected = "HomeRelocationRejected",
  HomeRelocationRequestAccepted = "HomeRelocationRequestAccepted",
  HomeRelocationRequestRejected = "HomeRelocationRequestRejected",
  HomeRelocationRequestSkipped = "HomeRelocationRequestSkipped",
  inclusionZoneViolation = "inclusionZoneViolation",
  inclusionZoneViolationOutcome = "inclusionZoneViolationOutcome",
  LocateNowContainer = "LocateNowContainer",
  LocateNowResponse = "LocateNowResponse",
  LocateNowResponse_BadLocation = "LocateNowResponse_BadLocation",
  locationUpdate = "locationUpdate",
  locationUpdate_BadLocation = "locationUpdate_BadLocation",
  MissedCheckIn = "MissedCheckIn",
  Note = "Note",
  NotRelocated = "NotRelocated",
  Onboarded = "Onboarded",
  OnboardingStepFinished = "OnboardingStepFinished",
  OnboardingStepStarted = "OnboardingStepStarted",
  orderedAppointment = "orderedAppointment",
  ParticipantPictureReceived = "ParticipantPictureReceived",
  PictureRequested = "PictureRequested",
  PictureUpdated = "PictureUpdated",
  QuestionsPrompted = "QuestionsPrompted",
  RawCheckIn = "RawCheckIn",
  RawLocateNowResponse = "RawLocateNowResponse",
  RawLocationUpdate = "RawLocationUpdate",
  RelocationAddressSubmitted = "RelocationAddressSubmitted",
  RelocationRequestSent = "RelocationRequestSent",
  RiskLevelChanged = "RiskLevelChanged",
  ScheduledCheckIn = "ScheduledCheckIn",
  ServiceStateChange = "ServiceStateChange",
  SignIn = "SignIn",
  SmsSentToParticipant = "SmsSentToParticipant",
  SubmitIssueRequested = "SubmitIssueRequested",
}

export type ConsentToReceiveSmsInput = {
  id: string;
  time: number;
};

export type ValidateParticipantImageInput = {
  s3ImagePath: string;
};

export type ValidateParticipantImageResponse = {
  __typename: "ValidateParticipantImageResponse";
  case: Case;
  faceMatched: boolean;
  validationProblem?: FaceValidationProblem | null;
};

export type Case = {
  __typename: "Case";
  caseId?: string | null;
  offenderId?: string | null;
  createdAt?: number | null;
  participant?: Participant | null;
  activeSince?: number | null;
  endDate?: string | null;
  authenticationMethod?: string | null;
  currentOfficerId?: string | null;
  currentOfficer?: Officer | null;
  startDate?: string | null;
  currentRiskLevel?: string | null;
  onboardingCompletedAt?: number | null;
  nextQuestions?: NextQuestionTimePrediction | null;
  questionAssignment?: QuestionAssignment | null;
  pendingQuestions?: PendingQuestions | null;
};

export type Participant = {
  __typename: "Participant";
  id?: string | null;
  birthday?: string | null;
  socialNumber?: string | null;
  homeLocation?: Address | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  gender?: Gender | null;
  docNumber?: string | null;
  docNumberNorm?: string | null;
  email?: string | null;
  emergencyContactPhoneNumber?: string | null;
  emergencyContactName?: string | null;
  secondaryContacts?: Array<SecondaryContact | null> | null;
  ethnicity?: string | null;
  phoneNumber?: string | null;
  alternativePhoneNumber?: string | null;
  timezone?: string | null;
  role?: string | null;
  logoImageS3Key?: string | null;
};

export type Address = {
  __typename: "Address";
  accuracy?: number | null;
  additionalInfo?: string | null;
  city?: string | null;
  coordinates?: Coordinates | null;
  country?: string | null;
  county?: string | null;
  formattedAddress?: string | null;
  geofence?: Geofence | null;
  label?: string | null;
  name?: string | null;
  number?: string | null;
  postalCode?: string | null;
  state?: string | null;
  stateShort?: string | null;
  street?: string | null;
  streetAddress?: string | null;
  sublocality?: string | null;
  time?: number | null;
  validatedAt?: number | null;
};

export type Coordinates = {
  __typename: "Coordinates";
  latitude?: number | null;
  longitude?: number | null;
};

export type Geofence = {
  __typename: "Geofence";
  radius?: number | null;
};

export enum Gender {
  F = "F",
  M = "M",
  Other = "Other",
}

export type SecondaryContact = {
  __typename: "SecondaryContact";
  email?: string | null;
  id: string;
  label?: string | null;
  language?: string | null;
  personName: string;
  phone?: string | null;
};

export type Officer = {
  __typename: "Officer";
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  active?: boolean | null;
  email?: string | null;
  phone?: string | null;
};

export type NextQuestionTimePrediction = {
  __typename: "NextQuestionTimePrediction";
  time?: number | null;
  notAvailableReason?: NextQuestionTimeNotAvailableReason | null;
};

export enum NextQuestionTimeNotAvailableReason {
  CaseClosed = "CaseClosed",
  NoQuestionsAssigned = "NoQuestionsAssigned",
  QuestionGroupArchived = "QuestionGroupArchived",
  QuestionGroupEmpty = "QuestionGroupEmpty",
  StartDateNotSetup = "StartDateNotSetup",
}

export type QuestionAssignment = {
  __typename: "QuestionAssignment";
  id?: string | null;
  period?: QuestionPeriod | null;
  agencyQuestionGroup?: AgencyQuestionGroup | null;
  questionGroupId?: string | null;
};

export enum QuestionPeriod {
  bimonthly = "bimonthly",
  biweekly = "biweekly",
  monthly = "monthly",
  weekly = "weekly",
}

export type AgencyQuestionGroup = {
  __typename: "AgencyQuestionGroup";
  agencyId?: string | null;
  id?: string | null;
  name?: string | null;
  localizedParticipantQuestions?: Array<LocalizedParticipantQuestion | null> | null;
  defaultGroup?: boolean | null;
};

export type LocalizedParticipantQuestion = {
  __typename: "LocalizedParticipantQuestion";
  id?: string | null;
  localization?: Array<LocalizedText | null> | null;
  createdAt?: number | null;
  authorUserId?: string | null;
  deletedAt?: number | null;
  deletedByUserId?: string | null;
};

export type LocalizedText = {
  __typename: "LocalizedText";
  locale: string;
  text?: string | null;
};

export type PendingQuestions = {
  __typename: "PendingQuestions";
  startTime?: number | null;
  answersValidUntil?: number | null;
  promptId?: string | null;
};

export enum FaceValidationProblem {
  FaceDoesNotMatch = "FaceDoesNotMatch",
  MultipleFaces = "MultipleFaces",
  NoFaces = "NoFaces",
}

export type CreateEventInput = {
  id?: string | null;
  time?: number | null;
  type: string;
  location?: string | null;
  parentEventId?: string | null;
  regionId?: string | null;
  relatedEvents?: string | null;
  regionType?: string | null;
  authenticationMethod?: string | null;
  network?: string | null;
  serviceName?: string | null;
  serviceState?: string | null;
  enabledProviders?: string | null;
  checkInId?: string | null;
  issueId?: string | null;
  eventId?: string | null;
  shotInfos?: string | null;
  checkInFailures?: Array<CheckInFailure | null> | null;
};

export enum CheckInFailure {
  noCameraPermission = "noCameraPermission",
  unknown = "unknown",
}

export type Event = {
  __typename: "Event";
  agencyId?: string | null;
  appointmentType?: AppointmentType | null;
  authenticationMethod?: string | null;
  attendanceAnswer?: string | null;
  batteryLevel?: BatteryLevel | null;
  bootEvent?: BootEvent | null;
  caseId?: string | null;
  checkInId?: string | null;
  checkInOrigination?: CheckInOrigination | null;
  checkInOutcome?: CheckInOutcome | null;
  checkInNow?: boolean | null;
  direction?: GeofenceDirection | null;
  enabledProviders?: Array<string | null> | null;
  eventId?: string | null;
  exclusionLocation?: ExclusionLocation | null;
  file?: S3Object | null;
  id: string;
  includeInCourtReport?: boolean | null;
  inclusionZone?: InclusionZone | null;
  inclusionZoneReturnInfo?: InclusionZoneReturnInfo | null;
  inclusionZoneViolationCheckOutcome?: InclusionZoneViolationCheckOutcome | null;
  inclusionZoneViolationCheckStatus?: InclusionZoneViolationCheckStatus | null;
  issueId?: string | null;
  legalCases?: Array<LegalCase | null> | null;
  locateNowRequestStatus?: LocateNowRequestStatus | null;
  location?: Address | null;
  locationPermissionsState?: ServicesState | null;
  locationProvidersState?: ServicesState | null;
  matterResolved?: boolean | null;
  network?: string | null;
  notes?: string | null;
  notificationsEnabled?: boolean | null;
  notificationsSoundEnabled?: boolean | null;
  offenderId?: string | null;
  parentEventId?: string | null;
  regionId?: string | null;
  regionType?: RegionType | null;
  relatedEvents?: Array<Event | null> | null;
  safeZone?: SafeZone | null;
  secondaryNotifications?: Array<SecondaryContactNotification | null> | null;
  serviceName?: ServiceName | null;
  serviceState?: ServicesState | null;
  shotInfos?: string | null;
  time: number;
  timeRange?: DateRange | null;
  title?: string | null;
  type: EventType;
};

export type AppointmentType = {
  __typename: "AppointmentType";
  active: boolean;
  agencyId: string;
  id: string;
  label?: string | null;
};

export enum BatteryLevel {
  low = "low",
  normal = "normal",
}

export enum BootEvent {
  booted = "booted",
  shutdown = "shutdown",
}

export enum CheckInOrigination {
  CheckInNow = "CheckInNow",
  CheckInSchedule = "CheckInSchedule",
  InclusionZone = "InclusionZone",
}

export enum CheckInOutcome {
  Missed = "Missed",
  Passed = "Passed",
  Scheduled = "Scheduled",
}

export enum GeofenceDirection {
  enter = "enter",
  exit = "exit",
}

export type ExclusionLocation = {
  __typename: "ExclusionLocation";
  address: Address;
  id: string;
  officerNotificationTypes?: Array<OfficerNotificationType | null> | null;
  secondaryNotifications?: Array<SecondaryNotification | null> | null;
};

export enum OfficerNotificationType {
  email = "email",
  systemAlert = "systemAlert",
  text = "text",
}

export type SecondaryNotification = {
  __typename: "SecondaryNotification";
  email?: Contact | null;
  personName: string;
  phone?: Contact | null;
};

export type Contact = {
  __typename: "Contact";
  notify?: boolean | null;
  value: string;
};

export type S3Object = {
  __typename: "S3Object";
  bucket: string;
  key: string;
  region: string;
};

export type InclusionZone = {
  __typename: "InclusionZone";
  address?: Address | null;
  id: string;
  name?: string | null;
  scheduleTextRepresentation?: string | null;
  type: InclusionZoneType;
};

export enum InclusionZoneType {
  CommunityService = "CommunityService",
  Home = "Home",
  School = "School",
}

export type InclusionZoneReturnInfo = {
  __typename: "InclusionZoneReturnInfo";
  locationEvent?: Event | null;
  returnType?: InclusionZoneReturnEventType | null;
  safeZone?: SafeZone | null;
};

export enum InclusionZoneReturnEventType {
  appearedInSafeZone = "appearedInSafeZone",
  returnedBack = "returnedBack",
}

export type SafeZone = {
  __typename: "SafeZone";
  address?: Address | null;
  id: string;
  name?: string | null;
};

export type InclusionZoneViolationCheckOutcome = {
  __typename: "InclusionZoneViolationCheckOutcome";
  closingEvent?: Event | null;
  lastLocation?: Address | null;
  lastLocationTime?: number | null;
  passReason?: InclusionZonePassReason | null;
  passed?: boolean | null;
  safeZone?: SafeZone | null;
  time?: number | null;
};

export enum InclusionZonePassReason {
  appearedInSafeZone = "appearedInSafeZone",
  returnedBack = "returnedBack",
  riskLevelSwitchedToNoTracking = "riskLevelSwitchedToNoTracking",
}

export enum InclusionZoneViolationCheckStatus {
  Failed = "Failed",
  Passed = "Passed",
  Pending = "Pending",
}

export type LegalCase = {
  __typename: "LegalCase";
  archivationInfo?: ArchivationInfo | null;
  caseId: string;
  caseNumber?: string | null;
  chargedBy?: string | null;
  charges?: Array<string | null> | null;
  closingAction?: LegalCaseClosingAction | null;
  createdAt?: number | null;
  id: string;
  participantId: string;
  restoreInfo?: RestoreInfo | null;
  updatedAt?: string | null;
};

export type ArchivationInfo = {
  __typename: "ArchivationInfo";
  archivedOn?: number | null;
};

export type LegalCaseClosingAction = {
  __typename: "LegalCaseClosingAction";
  courtAttendanceEventId?: string | null;
  courtEventId?: string | null;
  disposition?: LegalCaseDisposition | null;
  notes?: string | null;
  outcome: LegalCaseOutcome;
  time: number;
};

export enum LegalCaseDisposition {
  Jail = "Jail",
  None = "None",
  Prison = "Prison",
  Probation = "Probation",
  Sentenced = "Sentenced",
  SES = "SES",
  SIS = "SIS",
  Unknown = "Unknown",
}

export enum LegalCaseOutcome {
  Dismissed = "Dismissed",
  FelonyPledGuilty = "FelonyPledGuilty",
  Guilty = "Guilty",
  MisdemeanorPledGuilty = "MisdemeanorPledGuilty",
  NotGuilty = "NotGuilty",
  Unknown = "Unknown",
}

export type RestoreInfo = {
  __typename: "RestoreInfo";
  restoredOn?: number | null;
};

export enum LocateNowRequestStatus {
  RequestSent = "RequestSent",
  ResponseMissed = "ResponseMissed",
  ResponseReceived = "ResponseReceived",
}

export enum ServicesState {
  disabled = "disabled",
  enabled = "enabled",
  partial = "partial",
}

export enum RegionType {
  CourtDate = "CourtDate",
  ExclusionZoneFire = "ExclusionZoneFire",
  ExclusionZoneWarn = "ExclusionZoneWarn",
  Home = "Home",
  InclusionZone = "InclusionZone",
  OrderedAppointment = "OrderedAppointment",
  SafeZone = "SafeZone",
  Work = "Work",
}

export type SecondaryContactNotification = {
  __typename: "SecondaryContactNotification";
  byEmail?: boolean | null;
  byPhone?: boolean | null;
  contact?: SecondaryContact | null;
  secondaryContactId: string;
};

export enum ServiceName {
  Battery = "Battery",
  Boot = "Boot",
  LocationService = "LocationService",
  Notifications = "Notifications",
  NotificationsSound = "NotificationsSound",
}

export type DateRange = {
  __typename: "DateRange";
  end?: number | null;
  start?: number | null;
};

export type AddOnboardingStepInput = {
  sessionId: string;
  step: StepInput;
  workflow: string;
  time: number;
  deviceInfo: string;
};

export type StepInput = {
  index: number;
  name: string;
};

export type CompleteOnboardingInput = {
  sessionId: string;
  time: number;
  deviceInfo: string;
};

export type Appointment = {
  __typename: "Appointment";
  eventDay?: number | null;
  location?: Address | null;
  time?: number | null;
  type?: string | null;
  resolvedAppointmentType?: ResolvedAppointmentType | null;
  appointmentInstructions?: string | null;
  notes?: string | null;
};

export type ResolvedAppointmentType = {
  __typename: "ResolvedAppointmentType";
  id?: string | null;
  label?: string | null;
  active?: boolean | null;
};

export type ParticipantDocument = {
  __typename: "ParticipantDocument";
  id: string;
  name: string;
  category: Array<LocalizedText | null>;
  createdAt: number;
  contentType?: string | null;
  fileName?: string | null;
  read: boolean;
  scope: ParticipantDocumentScope;
};

export enum ParticipantDocumentScope {
  Agency = "Agency",
  Case = "Case",
}

export type DocumentDownloadLinkInput = {
  docId: string;
  documentScope: ParticipantDocumentScope;
};

export type TermsAndConditionsResponse = {
  __typename: "TermsAndConditionsResponse";
  document?: TermsAndConditionsDocument | null;
};

export type TermsAndConditionsDocument = {
  __typename: "TermsAndConditionsDocument";
  localization?: Array<LocalizedText | null> | null;
  time?: number | null;
  author?: string | null;
  version?: string | null;
};

export type LoadAgencyResourcesInput = {
  categoryId?: string | null;
  active?: boolean | null;
};

export type AgencyResource = {
  __typename: "AgencyResource";
  agencyId?: string | null;
  id?: string | null;
  title?: string | null;
  description?: string | null;
  keywords?: Array<string | null> | null;
  categoryId?: string | null;
  mainLocation?: ResourceLocation | null;
  locations?: Array<ResourceLocation | null> | null;
  createdAt?: number | null;
  createdBy?: string | null;
  deletedAt?: number | null;
  deletedBy?: string | null;
  category?: GlobalCategory | null;
};

export type ResourceLocation = {
  __typename: "ResourceLocation";
  name?: string | null;
  address?: Address | null;
  phones?: Array<ResourcePhone | null> | null;
  website?: string | null;
};

export type ResourcePhone = {
  __typename: "ResourcePhone";
  number?: string | null;
  actions?: Array<string | null> | null;
  description?: string | null;
  smsText?: string | null;
};

export type GlobalCategory = {
  __typename: "GlobalCategory";
  id?: string | null;
  localization?: Array<LocalizedText | null> | null;
  type?: GlobalCategoryType | null;
  createdAt?: number | null;
  createdBy?: string | null;
  deletedAt?: number | null;
  deletedBy?: string | null;
};

export enum GlobalCategoryType {
  agencyResource = "agencyResource",
  participantDocument = "participantDocument",
}

export type Agency = {
  __typename: "Agency";
  id?: string | null;
  name?: string | null;
  timezone?: string | null;
  address?: Address | null;
  issueDispatchingMethod?: string | null;
  disabledIssueTypes?: Array<IssueType | null> | null;
  deletedDefaultConditions?: Array<DefaultCondition | null> | null;
  areOnboardingResultsEnabled?: boolean | null;
  taskTypes?: Array<string | null> | null;
  canCreateTask?: boolean | null;
  configured?: boolean | null;
  email?: string | null;
  contact?: AgencyContact | null;
  enableBackupData?: boolean | null;
  inclusionZoneViolationThresholdInSeconds?: number | null;
  participantNotOnboardedNotification?: ParticipantNotOnboardedNotification | null;
  courtReportNotification?: AgencyCourtReportNotification | null;
  participantAbsenceSettingsPerRiskLevel?: ParticipantAbsenceSettings | null;
  missedCheckInGapInMinutes?: number | null;
  defaultCheckinScheduleByRiskLevel?: CheckInScheduleSettings | null;
  paymentOptions?: PaymentOptions | null;
  sessionTimeoutMins?: number | null;
  questionPeriod?: string | null;
  missedCheckinInRowWarningThreshold?: number | null;
  recordVideoChat?: boolean | null;
  workLoadStatsReportNotificationSetting?: AgencyWorkloadStatsReportNotificationSetting | null;
  parentId?: string | null;
  autoCloseIssue?: boolean | null;
  type?: AgencyType | null;
  placesEnabled?: boolean | null;
  peopleEnabled?: boolean | null;
  timeline?: boolean | null;
  lastCalculatedSnapshotDate?: string | null;
  receiveEmailIfNotOnboarded?: boolean | null;
};

export enum IssueType {
  BouncesComplaints = "BouncesComplaints",
  DeviceFailure = "DeviceFailure",
  ExclusionZoneViolation = "ExclusionZoneViolation",
  FailedCheckIn = "FailedCheckIn",
  InclusionZoneViolation = "InclusionZoneViolation",
  MissingGrants = "MissingGrants",
  MissingOnboarding = "MissingOnboarding",
  MissingQuestionAnswers = "MissingQuestionAnswers",
  ParticipantAbsence = "ParticipantAbsence",
  ParticipantReport = "ParticipantReport",
  PastCourtDate = "PastCourtDate",
  PeopleAssociations = "PeopleAssociations",
  PlacesChangeInTimeSpent = "PlacesChangeInTimeSpent",
  PlannedCourtDate = "PlannedCourtDate",
  PositiveAnswer = "PositiveAnswer",
  Relocation = "Relocation",
  ReviewParticipantPicture = "ReviewParticipantPicture",
  StopSms = "StopSms",
  SubmittedDocuments = "SubmittedDocuments",
  UserCrash = "UserCrash",
  UserCreatedTask = "UserCreatedTask",
  UserIssue = "UserIssue",
}

export enum DefaultCondition {
  Alcohol = "Alcohol",
  CommunityService = "CommunityService",
  CourtCosts = "CourtCosts",
  Curfew = "Curfew",
  Education = "Education",
  ElectronicMonitoring = "ElectronicMonitoring",
  Employment = "Employment",
  Firearms = "Firearms",
  MedicalTreatment = "MedicalTreatment",
  MentalHealthTreatment = "MentalHealthTreatment",
  Narcotics = "Narcotics",
  NoContact = "NoContact",
  SubstanceAbuseTherapy = "SubstanceAbuseTherapy",
  SubstanceUseTesting = "SubstanceUseTesting",
}

export type AgencyContact = {
  __typename: "AgencyContact";
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  cellPhone?: string | null;
  officePhone?: string | null;
};

export type ParticipantNotOnboardedNotification = {
  __typename: "ParticipantNotOnboardedNotification";
  notOnboardedMethod?: Array<AgencyParticipantNotOnboardedNotificationMethod | null> | null;
  email?: string | null;
};

export enum AgencyParticipantNotOnboardedNotificationMethod {
  EmailAgencyContact = "EmailAgencyContact",
  EmailCustom = "EmailCustom",
  EmailOfficer = "EmailOfficer",
}

export type AgencyCourtReportNotification = {
  __typename: "AgencyCourtReportNotification";
  method?: AgencyCourtReportNotificationMethod | null;
  email?: string | null;
};

export enum AgencyCourtReportNotificationMethod {
  EmailAgencyContact = "EmailAgencyContact",
  EmailCustom = "EmailCustom",
  EmailOfficer = "EmailOfficer",
  None = "None",
}

export type ParticipantAbsenceSettings = {
  __typename: "ParticipantAbsenceSettings";
  HIGH?: RiskLevelSetting | null;
  MEDIUM?: RiskLevelSetting | null;
  LOW?: RiskLevelSetting | null;
};

export type RiskLevelSetting = {
  __typename: "RiskLevelSetting";
  thresholdInMinutes?: number | null;
};

export type CheckInScheduleSettings = {
  __typename: "CheckInScheduleSettings";
  HIGH?: CheckinSchedule | null;
  MEDIUM?: CheckinSchedule | null;
  LOW?: CheckinSchedule | null;
};

export type CheckinSchedule = {
  __typename: "CheckinSchedule";
  createdAt?: number | null;
  updatedAt?: number | null;
  textRepresentation?: string | null;
  defaultSchedule?: boolean | null;
  days?: Array<CheckinScheduleDayEntry | null> | null;
};

export type CheckinScheduleDayEntry = {
  __typename: "CheckinScheduleDayEntry";
  day?: string | null;
  ranges?: Array<CheckinScheduleTimeEntry | null> | null;
};

export type CheckinScheduleTimeEntry = {
  __typename: "CheckinScheduleTimeEntry";
  checkinCount?: number | null;
  start?: string | null;
  end?: string | null;
  startedAtPreviousDay?: boolean | null;
  endedOnNextDay?: boolean | null;
};

export type PaymentOptions = {
  __typename: "PaymentOptions";
  type?: string | null;
  participantOneTimeFee?: number | null;
  participantMonthlyFee?: number | null;
};

export type AgencyWorkloadStatsReportNotificationSetting = {
  __typename: "AgencyWorkloadStatsReportNotificationSetting";
  selections?: Array<WorkLoadStatsReportMethodConfiguration | null> | null;
};

export type WorkLoadStatsReportMethodConfiguration = {
  __typename: "WorkLoadStatsReportMethodConfiguration";
  method?: WorkLoadStatsReportNotificationMethod | null;
  email?: string | null;
};

export enum WorkLoadStatsReportNotificationMethod {
  AgencyAdmin = "AgencyAdmin",
  AgencyContact = "AgencyContact",
  EmailCustom = "EmailCustom",
}

export enum AgencyType {
  Other = "Other",
  PostTrial = "PostTrial",
  PreTrial = "PreTrial",
}

export type UploadParticipantDocumentMutationVariables = {
  input: UploadParticipantDocumentInput;
};

export type UploadParticipantDocumentMutation = {
  uploadParticipantDocument?: boolean | null;
};

export type AcceptParticipantTermsAndConditionsMutationVariables = {
  input: AcceptTermsAndConditionsInput;
};

export type AcceptParticipantTermsAndConditionsMutation = {
  acceptParticipantTermsAndConditions?: string | null;
};

export type CreateParticipantAnswersMutationVariables = {
  input: CreateParticipantAnswersInput;
};

export type CreateParticipantAnswersMutation = {
  createParticipantAnswers?: boolean | null;
};

export type SaveRawEventMutationVariables = {
  input: SaveRawEventInput;
};

export type SaveRawEventMutation = {
  saveRawEvent?: boolean | null;
};

export type ConsentToReceiveSmsMutationVariables = {
  input: ConsentToReceiveSmsInput;
};

export type ConsentToReceiveSmsMutation = {
  consentToReceiveSms: boolean;
};

export type ValidateParticipantImageMutationVariables = {
  input: ValidateParticipantImageInput;
};

export type ValidateParticipantImageMutation = {
  validateParticipantImage: {
    __typename: "ValidateParticipantImageResponse";
    case: {
      __typename: "Case";
      caseId?: string | null;
      offenderId?: string | null;
      createdAt?: number | null;
      activeSince?: number | null;
      endDate?: string | null;
      authenticationMethod?: string | null;
      currentOfficerId?: string | null;
      startDate?: string | null;
      currentRiskLevel?: string | null;
      onboardingCompletedAt?: number | null;
    };
    faceMatched: boolean;
    validationProblem?: FaceValidationProblem | null;
  };
};

export type CreateEventMutationVariables = {
  input: CreateEventInput;
};

export type CreateEventMutation = {
  createEvent?: {
    __typename: "Event";
    agencyId?: string | null;
    appointmentType?: {
      __typename: "AppointmentType";
      active: boolean;
      agencyId: string;
      id: string;
      label?: string | null;
    } | null;
    authenticationMethod?: string | null;
    attendanceAnswer?: string | null;
    batteryLevel?: BatteryLevel | null;
    bootEvent?: BootEvent | null;
    caseId?: string | null;
    checkInId?: string | null;
    checkInOrigination?: CheckInOrigination | null;
    checkInOutcome?: CheckInOutcome | null;
    checkInNow?: boolean | null;
    direction?: GeofenceDirection | null;
    enabledProviders?: Array<string | null> | null;
    eventId?: string | null;
    exclusionLocation?: {
      __typename: "ExclusionLocation";
      id: string;
      officerNotificationTypes?: Array<OfficerNotificationType | null> | null;
    } | null;
    file?: {
      __typename: "S3Object";
      bucket: string;
      key: string;
      region: string;
    } | null;
    id: string;
    includeInCourtReport?: boolean | null;
    inclusionZone?: {
      __typename: "InclusionZone";
      id: string;
      name?: string | null;
      scheduleTextRepresentation?: string | null;
      type: InclusionZoneType;
    } | null;
    inclusionZoneReturnInfo?: {
      __typename: "InclusionZoneReturnInfo";
      returnType?: InclusionZoneReturnEventType | null;
    } | null;
    inclusionZoneViolationCheckOutcome?: {
      __typename: "InclusionZoneViolationCheckOutcome";
      lastLocationTime?: number | null;
      passReason?: InclusionZonePassReason | null;
      passed?: boolean | null;
      time?: number | null;
    } | null;
    inclusionZoneViolationCheckStatus?: InclusionZoneViolationCheckStatus | null;
    issueId?: string | null;
    legalCases?: Array<{
      __typename: "LegalCase";
      caseId: string;
      caseNumber?: string | null;
      chargedBy?: string | null;
      charges?: Array<string | null> | null;
      createdAt?: number | null;
      id: string;
      participantId: string;
      updatedAt?: string | null;
    } | null> | null;
    locateNowRequestStatus?: LocateNowRequestStatus | null;
    location?: {
      __typename: "Address";
      accuracy?: number | null;
      additionalInfo?: string | null;
      city?: string | null;
      country?: string | null;
      county?: string | null;
      formattedAddress?: string | null;
      label?: string | null;
      name?: string | null;
      number?: string | null;
      postalCode?: string | null;
      state?: string | null;
      stateShort?: string | null;
      street?: string | null;
      streetAddress?: string | null;
      sublocality?: string | null;
      time?: number | null;
      validatedAt?: number | null;
    } | null;
    locationPermissionsState?: ServicesState | null;
    locationProvidersState?: ServicesState | null;
    matterResolved?: boolean | null;
    network?: string | null;
    notes?: string | null;
    notificationsEnabled?: boolean | null;
    notificationsSoundEnabled?: boolean | null;
    offenderId?: string | null;
    parentEventId?: string | null;
    regionId?: string | null;
    regionType?: RegionType | null;
    relatedEvents?: Array<{
      __typename: "Event";
      agencyId?: string | null;
      authenticationMethod?: string | null;
      attendanceAnswer?: string | null;
      batteryLevel?: BatteryLevel | null;
      bootEvent?: BootEvent | null;
      caseId?: string | null;
      checkInId?: string | null;
      checkInOrigination?: CheckInOrigination | null;
      checkInOutcome?: CheckInOutcome | null;
      checkInNow?: boolean | null;
      direction?: GeofenceDirection | null;
      enabledProviders?: Array<string | null> | null;
      eventId?: string | null;
      id: string;
      includeInCourtReport?: boolean | null;
      inclusionZoneViolationCheckStatus?: InclusionZoneViolationCheckStatus | null;
      issueId?: string | null;
      locateNowRequestStatus?: LocateNowRequestStatus | null;
      locationPermissionsState?: ServicesState | null;
      locationProvidersState?: ServicesState | null;
      matterResolved?: boolean | null;
      network?: string | null;
      notes?: string | null;
      notificationsEnabled?: boolean | null;
      notificationsSoundEnabled?: boolean | null;
      offenderId?: string | null;
      parentEventId?: string | null;
      regionId?: string | null;
      regionType?: RegionType | null;
      serviceName?: ServiceName | null;
      serviceState?: ServicesState | null;
      shotInfos?: string | null;
      time: number;
      title?: string | null;
      type: EventType;
    } | null> | null;
    safeZone?: {
      __typename: "SafeZone";
      id: string;
      name?: string | null;
    } | null;
    secondaryNotifications?: Array<{
      __typename: "SecondaryContactNotification";
      byEmail?: boolean | null;
      byPhone?: boolean | null;
      secondaryContactId: string;
    } | null> | null;
    serviceName?: ServiceName | null;
    serviceState?: ServicesState | null;
    shotInfos?: string | null;
    time: number;
    timeRange?: {
      __typename: "DateRange";
      end?: number | null;
      start?: number | null;
    } | null;
    title?: string | null;
    type: EventType;
  } | null;
};

export type AddOnboardingStepMutationVariables = {
  input: AddOnboardingStepInput;
};

export type AddOnboardingStepMutation = {
  addOnboardingStep?: boolean | null;
};

export type CompleteOnboardingMutationVariables = {
  input: CompleteOnboardingInput;
};

export type CompleteOnboardingMutation = {
  completeOnboarding?: boolean | null;
};

export type GetParticipantCaseQueryVariables = {};

export type GetParticipantCaseQuery = {
  getParticipantCase?: {
    __typename: "Case";
    caseId?: string | null;
    offenderId?: string | null;
    createdAt?: number | null;
    participant?: {
      __typename: "Participant";
      id?: string | null;
      birthday?: string | null;
      socialNumber?: string | null;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: Gender | null;
      docNumber?: string | null;
      docNumberNorm?: string | null;
      email?: string | null;
      emergencyContactPhoneNumber?: string | null;
      emergencyContactName?: string | null;
      ethnicity?: string | null;
      phoneNumber?: string | null;
      alternativePhoneNumber?: string | null;
      timezone?: string | null;
      role?: string | null;
      logoImageS3Key?: string | null;
    } | null;
    activeSince?: number | null;
    endDate?: string | null;
    authenticationMethod?: string | null;
    currentOfficerId?: string | null;
    currentOfficer?: {
      __typename: "Officer";
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      active?: boolean | null;
      email?: string | null;
      phone?: string | null;
    } | null;
    startDate?: string | null;
    currentRiskLevel?: string | null;
    onboardingCompletedAt?: number | null;
    nextQuestions?: {
      __typename: "NextQuestionTimePrediction";
      time?: number | null;
      notAvailableReason?: NextQuestionTimeNotAvailableReason | null;
    } | null;
    questionAssignment?: {
      __typename: "QuestionAssignment";
      id?: string | null;
      period?: QuestionPeriod | null;
      questionGroupId?: string | null;
    } | null;
    pendingQuestions?: {
      __typename: "PendingQuestions";
      startTime?: number | null;
      answersValidUntil?: number | null;
      promptId?: string | null;
    } | null;
  } | null;
};

export type GetFutureAppointmentsQueryVariables = {};

export type GetFutureAppointmentsQuery = {
  getFutureAppointments?: Array<{
    __typename: "Appointment";
    eventDay?: number | null;
    location?: {
      __typename: "Address";
      accuracy?: number | null;
      additionalInfo?: string | null;
      city?: string | null;
      country?: string | null;
      county?: string | null;
      formattedAddress?: string | null;
      label?: string | null;
      name?: string | null;
      number?: string | null;
      postalCode?: string | null;
      state?: string | null;
      stateShort?: string | null;
      street?: string | null;
      streetAddress?: string | null;
      sublocality?: string | null;
      time?: number | null;
      validatedAt?: number | null;
    } | null;
    time?: number | null;
    type?: string | null;
    resolvedAppointmentType?: {
      __typename: "ResolvedAppointmentType";
      id?: string | null;
      label?: string | null;
      active?: boolean | null;
    } | null;
    appointmentInstructions?: string | null;
    notes?: string | null;
  } | null> | null;
};

export type GetPendingCourtRemindersQueryVariables = {};

export type GetPendingCourtRemindersQuery = {
  getPendingCourtReminders?: Array<{
    __typename: "Appointment";
    eventDay?: number | null;
    location?: {
      __typename: "Address";
      accuracy?: number | null;
      additionalInfo?: string | null;
      city?: string | null;
      country?: string | null;
      county?: string | null;
      formattedAddress?: string | null;
      label?: string | null;
      name?: string | null;
      number?: string | null;
      postalCode?: string | null;
      state?: string | null;
      stateShort?: string | null;
      street?: string | null;
      streetAddress?: string | null;
      sublocality?: string | null;
      time?: number | null;
      validatedAt?: number | null;
    } | null;
    time?: number | null;
    type?: string | null;
    resolvedAppointmentType?: {
      __typename: "ResolvedAppointmentType";
      id?: string | null;
      label?: string | null;
      active?: boolean | null;
    } | null;
    appointmentInstructions?: string | null;
    notes?: string | null;
  } | null> | null;
};

export type GetDocumentListQueryVariables = {};

export type GetDocumentListQuery = {
  getDocumentList?: Array<{
    __typename: "ParticipantDocument";
    id: string;
    name: string;
    category: Array<{
      __typename: "LocalizedText";
      locale: string;
      text?: string | null;
    } | null>;
    createdAt: number;
    contentType?: string | null;
    fileName?: string | null;
    read: boolean;
    scope: ParticipantDocumentScope;
  } | null> | null;
};

export type GetDocumentDownloadLinkQueryVariables = {
  input?: DocumentDownloadLinkInput | null;
};

export type GetDocumentDownloadLinkQuery = {
  getDocumentDownloadLink?: string | null;
};

export type GetTermsAndConditionsQueryVariables = {};

export type GetTermsAndConditionsQuery = {
  getTermsAndConditions?: {
    __typename: "TermsAndConditionsResponse";
    document?: {
      __typename: "TermsAndConditionsDocument";
      time?: number | null;
      author?: string | null;
      version?: string | null;
    } | null;
  } | null;
};

export type GetScheduledCheckInsQueryVariables = {};

export type GetScheduledCheckInsQuery = {
  getScheduledCheckIns?: Array<{
    __typename: "Event";
    agencyId?: string | null;
    appointmentType?: {
      __typename: "AppointmentType";
      active: boolean;
      agencyId: string;
      id: string;
      label?: string | null;
    } | null;
    authenticationMethod?: string | null;
    attendanceAnswer?: string | null;
    batteryLevel?: BatteryLevel | null;
    bootEvent?: BootEvent | null;
    caseId?: string | null;
    checkInId?: string | null;
    checkInOrigination?: CheckInOrigination | null;
    checkInOutcome?: CheckInOutcome | null;
    checkInNow?: boolean | null;
    direction?: GeofenceDirection | null;
    enabledProviders?: Array<string | null> | null;
    eventId?: string | null;
    exclusionLocation?: {
      __typename: "ExclusionLocation";
      id: string;
      officerNotificationTypes?: Array<OfficerNotificationType | null> | null;
    } | null;
    file?: {
      __typename: "S3Object";
      bucket: string;
      key: string;
      region: string;
    } | null;
    id: string;
    includeInCourtReport?: boolean | null;
    inclusionZone?: {
      __typename: "InclusionZone";
      id: string;
      name?: string | null;
      scheduleTextRepresentation?: string | null;
      type: InclusionZoneType;
    } | null;
    inclusionZoneReturnInfo?: {
      __typename: "InclusionZoneReturnInfo";
      returnType?: InclusionZoneReturnEventType | null;
    } | null;
    inclusionZoneViolationCheckOutcome?: {
      __typename: "InclusionZoneViolationCheckOutcome";
      lastLocationTime?: number | null;
      passReason?: InclusionZonePassReason | null;
      passed?: boolean | null;
      time?: number | null;
    } | null;
    inclusionZoneViolationCheckStatus?: InclusionZoneViolationCheckStatus | null;
    issueId?: string | null;
    legalCases?: Array<{
      __typename: "LegalCase";
      caseId: string;
      caseNumber?: string | null;
      chargedBy?: string | null;
      charges?: Array<string | null> | null;
      createdAt?: number | null;
      id: string;
      participantId: string;
      updatedAt?: string | null;
    } | null> | null;
    locateNowRequestStatus?: LocateNowRequestStatus | null;
    location?: {
      __typename: "Address";
      accuracy?: number | null;
      additionalInfo?: string | null;
      city?: string | null;
      country?: string | null;
      county?: string | null;
      formattedAddress?: string | null;
      label?: string | null;
      name?: string | null;
      number?: string | null;
      postalCode?: string | null;
      state?: string | null;
      stateShort?: string | null;
      street?: string | null;
      streetAddress?: string | null;
      sublocality?: string | null;
      time?: number | null;
      validatedAt?: number | null;
    } | null;
    locationPermissionsState?: ServicesState | null;
    locationProvidersState?: ServicesState | null;
    matterResolved?: boolean | null;
    network?: string | null;
    notes?: string | null;
    notificationsEnabled?: boolean | null;
    notificationsSoundEnabled?: boolean | null;
    offenderId?: string | null;
    parentEventId?: string | null;
    regionId?: string | null;
    regionType?: RegionType | null;
    relatedEvents?: Array<{
      __typename: "Event";
      agencyId?: string | null;
      authenticationMethod?: string | null;
      attendanceAnswer?: string | null;
      batteryLevel?: BatteryLevel | null;
      bootEvent?: BootEvent | null;
      caseId?: string | null;
      checkInId?: string | null;
      checkInOrigination?: CheckInOrigination | null;
      checkInOutcome?: CheckInOutcome | null;
      checkInNow?: boolean | null;
      direction?: GeofenceDirection | null;
      enabledProviders?: Array<string | null> | null;
      eventId?: string | null;
      id: string;
      includeInCourtReport?: boolean | null;
      inclusionZoneViolationCheckStatus?: InclusionZoneViolationCheckStatus | null;
      issueId?: string | null;
      locateNowRequestStatus?: LocateNowRequestStatus | null;
      locationPermissionsState?: ServicesState | null;
      locationProvidersState?: ServicesState | null;
      matterResolved?: boolean | null;
      network?: string | null;
      notes?: string | null;
      notificationsEnabled?: boolean | null;
      notificationsSoundEnabled?: boolean | null;
      offenderId?: string | null;
      parentEventId?: string | null;
      regionId?: string | null;
      regionType?: RegionType | null;
      serviceName?: ServiceName | null;
      serviceState?: ServicesState | null;
      shotInfos?: string | null;
      time: number;
      title?: string | null;
      type: EventType;
    } | null> | null;
    safeZone?: {
      __typename: "SafeZone";
      id: string;
      name?: string | null;
    } | null;
    secondaryNotifications?: Array<{
      __typename: "SecondaryContactNotification";
      byEmail?: boolean | null;
      byPhone?: boolean | null;
      secondaryContactId: string;
    } | null> | null;
    serviceName?: ServiceName | null;
    serviceState?: ServicesState | null;
    shotInfos?: string | null;
    time: number;
    timeRange?: {
      __typename: "DateRange";
      end?: number | null;
      start?: number | null;
    } | null;
    title?: string | null;
    type: EventType;
  } | null> | null;
};

export type LoadAgencyResourcesQueryVariables = {
  input: LoadAgencyResourcesInput;
};

export type LoadAgencyResourcesQuery = {
  loadAgencyResources: Array<{
    __typename: "AgencyResource";
    agencyId?: string | null;
    id?: string | null;
    title?: string | null;
    description?: string | null;
    keywords?: Array<string | null> | null;
    categoryId?: string | null;
    mainLocation?: {
      __typename: "ResourceLocation";
      name?: string | null;
      website?: string | null;
    } | null;
    locations?: Array<{
      __typename: "ResourceLocation";
      name?: string | null;
      website?: string | null;
    } | null> | null;
    createdAt?: number | null;
    createdBy?: string | null;
    deletedAt?: number | null;
    deletedBy?: string | null;
    category?: {
      __typename: "GlobalCategory";
      id?: string | null;
      type?: GlobalCategoryType | null;
      createdAt?: number | null;
      createdBy?: string | null;
      deletedAt?: number | null;
      deletedBy?: string | null;
    } | null;
  } | null>;
};

export type GetAgencyQueryVariables = {};

export type GetAgencyQuery = {
  getAgency?: {
    __typename: "Agency";
    id?: string | null;
    name?: string | null;
    timezone?: string | null;
    address?: {
      __typename: "Address";
      accuracy?: number | null;
      additionalInfo?: string | null;
      city?: string | null;
      country?: string | null;
      county?: string | null;
      formattedAddress?: string | null;
      label?: string | null;
      name?: string | null;
      number?: string | null;
      postalCode?: string | null;
      state?: string | null;
      stateShort?: string | null;
      street?: string | null;
      streetAddress?: string | null;
      sublocality?: string | null;
      time?: number | null;
      validatedAt?: number | null;
    } | null;
    issueDispatchingMethod?: string | null;
    disabledIssueTypes?: Array<IssueType | null> | null;
    deletedDefaultConditions?: Array<DefaultCondition | null> | null;
    areOnboardingResultsEnabled?: boolean | null;
    taskTypes?: Array<string | null> | null;
    canCreateTask?: boolean | null;
    configured?: boolean | null;
    email?: string | null;
    contact?: {
      __typename: "AgencyContact";
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      cellPhone?: string | null;
      officePhone?: string | null;
    } | null;
    enableBackupData?: boolean | null;
    inclusionZoneViolationThresholdInSeconds?: number | null;
    participantNotOnboardedNotification?: {
      __typename: "ParticipantNotOnboardedNotification";
      notOnboardedMethod?: Array<AgencyParticipantNotOnboardedNotificationMethod | null> | null;
      email?: string | null;
    } | null;
    courtReportNotification?: {
      __typename: "AgencyCourtReportNotification";
      method?: AgencyCourtReportNotificationMethod | null;
      email?: string | null;
    } | null;
    participantAbsenceSettingsPerRiskLevel?: {
      __typename: "ParticipantAbsenceSettings";
    } | null;
    missedCheckInGapInMinutes?: number | null;
    defaultCheckinScheduleByRiskLevel?: {
      __typename: "CheckInScheduleSettings";
    } | null;
    paymentOptions?: {
      __typename: "PaymentOptions";
      type?: string | null;
      participantOneTimeFee?: number | null;
      participantMonthlyFee?: number | null;
    } | null;
    sessionTimeoutMins?: number | null;
    questionPeriod?: string | null;
    missedCheckinInRowWarningThreshold?: number | null;
    recordVideoChat?: boolean | null;
    workLoadStatsReportNotificationSetting?: {
      __typename: "AgencyWorkloadStatsReportNotificationSetting";
    } | null;
    parentId?: string | null;
    autoCloseIssue?: boolean | null;
    type?: AgencyType | null;
    placesEnabled?: boolean | null;
    peopleEnabled?: boolean | null;
    timeline?: boolean | null;
    lastCalculatedSnapshotDate?: string | null;
    receiveEmailIfNotOnboarded?: boolean | null;
  } | null;
};
