import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import { ApolloProviderWrapper } from "./components/ApolloProviderWrapper";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

// Info on React Strict Mode: https://legacy.reactjs.org/docs/strict-mode.html
root.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <ApolloProviderWrapper>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApolloProviderWrapper>
    </Authenticator.Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
